<template>
    <div class="indexcenter">
      <!--   首页头部   -->
      <img src="../assets/img/index/banner1.png" class="bannerBg" alt="">
      <div class="backimg1" id="backimg">
        <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
        <img src="../assets/img/index/logo.png" class="logo" alt="">
        <p class="tipsCn">世界上最伟大的商业是传递光明 服务和产品仅是载体</p>
        <p class="tipsEn">The greatest business in the world is to transmit bright , while services and products are only the carriers .</p>
        <div v-if="heardersw">
          <titletop />
        </div>
        <div class="navBox">
          <div class="nav">
            <span>首页</span>
            <a class="active" href="#backimg"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团介绍</span>
            <a href="#jieshao"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">新闻资讯</span>
            <a href="#zixun"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团产品</span>
            <a href="#chanpin"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">尾页</span>
            <a href="#footer"></a>
          </div>
        </div>
      </div>
      <!--   集团介绍   -->
      <div class="jieshao" id="jieshao">
        <div style="margin-top: 300px">
          <p class="h1">ABOUT</p>
          <span class="h2">集团介绍</span>
        </div>
        <div class="contentText">
          上海鸿旗发展集团有限公司是金属（新）材料现代服务型企业
          构建涵盖产业互联网、尾货处置、产业供应链、新材料研究、
          金属材料采购服务、贸易等细分领域需求的完整现代服务体系。
        </div>
        <em class="line"></em>
        <router-link  to="/qiyejs" style="text-decoration: none;">
          <div class="toDetail">
            <p>查看详情</p>
            <img src="../assets/img/index/toDetail.png" alt="">
          </div>
        </router-link>

        <div class="jieshaoBox right861">
          <p>
            鸿<br/>
            旗<br/>
            企<br/>
            业
          </p>
          <span>
            L E T&nbsp;&nbsp;&nbsp;T H E&nbsp;&nbsp;&nbsp;&nbsp;E N T E R P R I S E&nbsp;&nbsp;&nbsp;&nbsp;D E V E L O P
          </span>
        </div>
        <div class="jieshaoBox right710">
          <p>
            让<br/>
            客<br/>
            户<br/>
            感<br/>
            动
          </p>
          <span>
            M A K E&nbsp;&nbsp;&nbsp;C U S T O M E R S&nbsp;&nbsp;&nbsp;M O V E D
          </span>
        </div>
        <div class="jieshaoBox right559">
          <p>
            让<br/>
            员<br/>
            工<br/>
            幸<br/>
            福
          </p>
          <span>
            M A K E&nbsp;&nbsp;&nbsp;E M P L O Y E E S&nbsp;&nbsp;&nbsp;H A P P I N E S S
          </span>
        </div>
        <div class="jieshaoBox right408">
          <p>
            让<br/>
            社<br/>
            会<br/>
            尊<br/>
            重
          </p>
          <span>
            L E T&nbsp;&nbsp;&nbsp;S O C I E T Y&nbsp;&nbsp;&nbsp;R E S P E C T
          </span>
        </div>
        <div class="jieshaoBox1">
          <div class="jieshaoText">
            <p>
              让<br/>
              祖<br/>
              国<br/>
              放<br/>
              心
            </p>
            <span>
            L E T&nbsp;&nbsp;&nbsp;T H E&nbsp;&nbsp;&nbsp;M O T H E R L A N D&nbsp;&nbsp;&nbsp;R E S T&nbsp;&nbsp;&nbsp;A S S U R E D
          </span>
          </div>
        </div>
        <div class="navBox">
          <div class="nav">
            <span style="visibility: hidden">首页</span>
            <a href="#backimg"></a>
          </div>
          <div class="nav">
            <span>集团介绍</span>
            <a class="active" href="#jieshao"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">新闻资讯</span>
            <a href="#zixun"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团产品</span>
            <a href="#chanpin"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">尾页</span>
            <a href="#footer"></a>
          </div>
        </div>
      </div>

      <!--   新闻资讯   -->
      <div class="zixun" id="zixun">
        <div class="contentBox">
          <p class="news">
            N E W S
          </p>
          <span>
            新<br/>
            闻<br/>
            资<br/>
            讯
          </span>
          <em></em>
          <img src="../assets/img/index/zixunTextBg.png" alt="">
          <div class="textBox">
            <div class="hangye">
              行业咨询
            </div>
            <p>
              钢铁行业重大新闻！日前，宝武集团……
            </p>
          </div>
          <router-link  to="/subordinate" style="text-decoration: none;">
            <div class="toDetail">
              <p>查看详情</p>
              <img src="../assets/img/index/toDetail.png" alt="">
            </div>
          </router-link>
        </div>
        <div class="navBox">
          <div class="nav">
            <span style="visibility: hidden">首页</span>
            <a href="#backimg"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团介绍</span>
            <a href="#jieshao"></a>
          </div>
          <div class="nav">
            <span>新闻资讯</span>
            <a class="active" href="#zixun"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团产品</span>
            <a href="#chanpin"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">尾页</span>
            <a href="#footer"></a>
          </div>
        </div>
      </div>
      <!--   集团产品   -->
      <div class="chanpin" id="chanpin">
        <p>PRODUCT</p>
        <span>集团产品</span>
        <div class="line">
          <em></em>
        </div>
        <swiperCoverflow
            :slide-index="initialSlide"
            :img-list="imgList"
        />
        <div class="navBox">
          <div class="nav">
            <span style="visibility: hidden">首页</span>
            <a href="#backimg"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团介绍</span>
            <a href="#jieshao"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">新闻资讯</span>
            <a href="#zixun"></a>
          </div>
          <div class="nav">
            <span>集团产品</span>
            <a class="active" href="#chanpin"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">尾页</span>
            <a href="#footer"></a>
          </div>
        </div>
      </div>
      <!--   footer   -->
      <div class="footer" id="footer">
        <p>我们已为4000家制造业企业提供供应链服务</p>
        <div class="navBox">
          <div class="nav">
            <span style="visibility: hidden">首页</span>
            <a href="#backimg"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团介绍</span>
            <a href="#jieshao"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">新闻资讯</span>
            <a href="#zixun"></a>
          </div>
          <div class="nav">
            <span style="visibility: hidden">集团产品</span>
            <a href="#chanpin"></a>
          </div>
          <div class="nav">
            <span>尾页</span>
            <a class="active" href="#footer"></a>
          </div>
        </div>
      </div>

     <Foot />
    </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import swiperCoverflow from '../components/shehui/swiperCoverflow.vue'
export default {
  data(){
   return{
     heardersw:false,
     swxkone:false,
     imgList: [
       {
         src:require("../assets/img/index/product/productBg.png")
       },
       {
         src:require("../assets/img/index/product/productBg1.png")
       },
       {
         src:require("../assets/img/index/product/productBg3.png")
       },
       {
         src:require("../assets/img/index/product/productBg4.png")
       },
       {
         src:require("../assets/img/index/product/productBg5.png")
       },
       {
         src:require("../assets/img/index/product/productBg6.png")
       },
       {
         src:require("../assets/img/index/product/productBg7.png")
       },
       {
         src:require("../assets/img/index/product/productBg8.png")
       },
       {
         src:require("../assets/img/index/product/productBg9.png")
       },
     ],
     initialSlide: 4
   }
  },
  mounted(){
     //判断到达底部
   window.onscroll = function(){

      //定义变量获取屏幕视口宽度
    let windowWidth = document.body.clientWidth;
    if(windowWidth>700){
       //变量scrollTop是滚动条滚动时，距离顶部的距离
      let winscrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      let panel = document.getElementById("panel").offsetTop;
      // let approach = document.getElementById("approach").offsetTop;
      // let graphene = document.getElementById("graphene").offsetTop;
      // let xkright = document.getElementById("xkright").offsetTop;
      
    console.log(winscrollTop);
        if(winscrollTop+500>=panel){  
          console.log(this.swxkone);
              document.getElementById("panel").className ="xkone animationone";
              document.getElementById("panel").style.display = "block";
              document.getElementById("approach").className ="xktwo animationtwo";
              document.getElementById("xkright").className ="xkright animationthree";
              document.getElementById("approach").style.display = "block";
              document.getElementById("xkright").style.display = "block";
              document.getElementById("graphene").className ="xkthree animationthree";
              document.getElementById("graphene").style.display = "block";
            
      }
      else{
         document.getElementById("panel").className ="xkone";
         document.getElementById("panel").style.display = "none";
         document.getElementById("approach").className ="xktwo";
         document.getElementById("xkright").className ="xkright";
         document.getElementById("approach").style.display = "none";
         document.getElementById("xkright").style.display = "none";
         document.getElementById("graphene").className ="xkthree";
         document.getElementById("graphene").style.display = "none";
         
      }
      if(winscrollTop+300>=panel){  
          console.log(this.swxkone);
              document.getElementById("panel").className ="xkone animationone";
              document.getElementById("panel").style.display = "block";
              document.getElementById("approach").className ="xktwo animationtwo";
              document.getElementById("xkright").className ="xkright animationthree";
              document.getElementById("approach").style.display = "block";
              document.getElementById("xkright").style.display = "block";
              document.getElementById("graphene").className ="xkthree animationthree";
              document.getElementById("graphene").style.display = "block";
            
      }
      else{
         document.getElementById("panel").className ="xkone";
         document.getElementById("panel").style.display = "none";
         document.getElementById("approach").className ="xktwo";
         document.getElementById("xkright").className ="xkright";
         document.getElementById("approach").style.display = "none";
         document.getElementById("xkright").style.display = "none";
         document.getElementById("graphene").className ="xkthree";
         document.getElementById("graphene").style.display = "none";
         
      }
   
   
    
   
    }
  }
  
  },
  methods:{
    showhearder(){
      this.heardersw =! this.heardersw
    },
    toDetail() {}
  },
  name: 'App',
  components: {
    Foot,
    titletop,
    swiperCoverflow
  }
}
</script>
<style lang="scss" scope>
.indexcenter{
  overflow: hidden;
  .hearderimg{
    width: 29px;
    height: 21px;
    position: absolute;
    right: 50px;
    top: 20px;
  }
  .showhearder{
    padding: 50px ;
    padding-top: 100px;

    width: 345px;
    height: 608px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    z-index: 10;
    .heaitem{
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 30px 0;
      .heaitemtext{
        // font-size: 18px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .ullist{
      padding-left: 80px;
      .liitem{
        list-style: none;
        margin: 20px 0;
      }
      .liactive{
        color: rgb(230,82,86);
      }

    }
  }
  .backsmaimg{
    width: 36%;
    height: 300px;
    border-radius: 8px;
    background-image: url(../assets/img/bigbanhder.png);
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -100px;
    right: 220px;
    .posionband{
      position: absolute;
      // border: 1px solid red;
      left: 100px;
      top: 80px;
      color: white;
      .posionbando{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 74px;
      }
      .posionbandt{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .optionbander{
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix{
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 89px;
      letter-spacing: 12px;
    }
    .smallsix{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }
  .bander{
    width: 100%;
  }
  .banderdsd{
    // width: 700px;
    margin-left: 797px;
    margin-top: -173px;
  }
  @keyframes bannerHide {
    from {
      opacity: 1;
      z-index: 9;
    }
    to {
      opacity: 0;
      z-index: 0;
    }
  }
  .bannerBg {
    width: 100%;
    height: 932px;
    position: absolute;
    top: 0;
    opacity: 0;
    animation: 6s bannerHide;
  }
 .backimg1{
  // border: 1px solid red;
   width: 100%;
  height: 932px;
  background-image: url(../assets/img/index/banner.png);
  background-position: 100%;
  background-size: 100% 100%;
  position: relative;

   .tipsCn {
     font-size: 38px;
     position: absolute;
     top: 648px;
     left: 760px;
   }
   .tipsEn {
     font-size: 24px;
     position: absolute;
     top: 724px;
     left: 540px;
   }
   .logo {
     width: 185px;
     height: 49px;
     margin: 62px 0 0 61px;
   }
   .title {
     margin: 180px 0 0 495px;
     font-size: 38px;
     font-family: PingFangSC-Regular, PingFang SC;
     font-weight: 400;
     color: #C82227;
     line-height: 53px;
     letter-spacing: 3px;
   }
   .honorRedBox {
     position: relative;
     top: 47px;
     left: 698px;
     img {
       position: absolute;
     }
     img:nth-child(1) {
       left: 9px;
     }
   }
   .flagBg {
     position: absolute;
     top: 625px;
     left: 1233px;
   }
   .navBox {
     position: absolute;
     top: 452px;
     right: 30px;
     .nav {
       margin-top: 15px;
       display: flex;
       align-items: center;
       span {
         display: block;
         width: 60px;
         font-size: 14px;
         font-family: PingFangSC-Regular, PingFang SC;
         font-weight: 400;
         color: #000000;
         line-height: 21px;
         text-align: right;
         margin-right: 10px;
       }
       a {
         display: block;
         width: 16px;
         height: 16px;
         border: 1px solid #000000;
         border-radius: 50%;
       }
     }
     .nav:nth-child(1) {
       margin-top: 0;
     }
     .active {
       background: #000000;
     }
   }
 }
  .jieshao {
    width: 100%;
    height: 926px;
    background-image: url(../assets/img/index/jieshaoBg.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    box-sizing: border-box;
    padding-left: 160px;
    overflow: hidden;
    .h1 {
      font-size: 14px;
      font-family: AppleSystemUIFont;
      color: #666666;
      line-height: 21px;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
    .h2 {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 41px;
    }
    .contentText {
      width: 504px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 36px;
      margin-top: 23px;
    }
    .line {
      display: block;
      width: 420px;
      height: 3px;
      background: #C82227;
      margin-top: 38px;
    }
    .toDetail {
      display: flex;
      align-items: center;
      margin-top: 42px;
      p {
        font-size: 18px;
        font-family: AppleSystemUIFont;
        color: #C82227;
        line-height: 27px;
        margin-right: 10px;
      }
      img {
        width: 21px;
        height: 21px;
      }
    }
    .jieshaoBox {
      width: 151px;
      background-image: url(../assets/img/index/jieshaoBg1.png);
      background-position: 100%;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding-top: 185px;
      position: absolute;
      top: 0;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      p {
        display: block;
        height: 180px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 36px;
        letter-spacing: 2px;
      }
      span {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(153,153,153,0.6);
        line-height: 27px;
        transform:rotate(90deg);
        text-align: left;
        display: block;
        width: 600px;
        margin-top: 310px;
      }
    }
    .right861 {
      right: 861px;
    }
    .right710 {
      height: 747px;
      right: 710px;
    }
    .right559 {
      height: 697px;
      right: 559px;
    }
    .right408 {
      height: 721px;
      right: 408px;
    }
    .jieshaoBox1 {
      width: 408px;
      height: 651px;
      background-image: url(../assets/img/index/jieshaoBg2.png);
      background-position: 100%;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: 0;
      .jieshaoText {
        width: 40px;
        height: 100%;
        box-sizing: border-box;
        padding-top: 185px;
        padding-left: 68px;
        align-items: center;
        display: flex;
        flex-direction: column;
        p {
          display: block;
          height: 180px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 36px;
          letter-spacing: 2px;
        }
        span {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(153,153,153,0.6);
          line-height: 27px;
          transform:rotate(90deg);
          display: block;
          width: 600px;
          margin-top: 310px;
        }
      }
    }
    .navBox {
      position: absolute;
      right: 30px;
      top: 456px;
      .nav {
        margin-top: 15px;
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 60px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 21px;
          text-align: right;
          margin-right: 10px;
        }
        a {
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #000000;
          border-radius: 50%;
        }
      }
      .nav:nth-child(1) {
        margin-top: 0;
      }
      .active {
        background: #000000;
      }
    }
  }
  .zixun {
    width: 100%;
    height: 927px;
    background-image: url(../assets/img/index/zixunBg.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    box-sizing: border-box;
    padding-left: 200px;
    padding-top: 185px;
    .contentBox {
      height: 546px;
      display: flex;
      position: relative;
      left: 40px;
      .news {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
        width: 100px;
        transform:rotate(90deg);
        position: absolute;
        left: -4.5%;
        top: 4%;
        display: block;
        line-height: 16px;
      }
      span {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
      em {
        display: block;
        width: 3px;
        height: 162px;
        background: #C82227;
        margin-left: 23px;
        margin-right: 31px;
      }
      .textBox {
        width: 780px;
        height: 200px;
        background: #FFFFFF;
        border-radius: 8px 0 8px 8px;
        position: absolute;
        top: 176px;
        right: 200px;
        .hangye {
          width: 254px;
          height: 93px;
          background: #f5f5f5;
          border-radius: 8px 0 8px 8px;
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #C82227;
          letter-spacing: 3px;
          text-align: center;
          line-height: 93px;
        }
        p {
          padding-left: 50px;
          font-size: 30px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #000000;
          line-height: 45px;
          letter-spacing: 3px;
          margin-top: 20px;
        }
      }
      .toDetail {
        position: absolute;
        bottom: 0;
        right: 195px;
        display: flex;
        align-items: center;
        margin-top: 42px;
        p {
          font-size: 18px;
          font-family: AppleSystemUIFont;
          color: #C82227;
          line-height: 27px;
          margin-right: 10px;
        }
        img {
          width: 21px;
          height: 21px;
        }
      }
    }
    .navBox {
      position: absolute;
      right: 30px;
      top: 456px;
      .nav {
        margin-top: 15px;
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 60px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 21px;
          text-align: right;
          margin-right: 10px;
        }
        a {
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #000000;
          border-radius: 50%;
        }
      }
      .nav:nth-child(1) {
        margin-top: 0;
      }
      .active {
        background: #000000;
      }
    }
  }
  .chanpin {
    width: 100%;
    height: 927px;
    background-image: url(../assets/img/index/chanpinBg.png);
    background-position: 100%;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;
    position: relative;
    p {
      font-size: 14px;
      font-family: AppleSystemUIFont;
      color: #666666;
      line-height: 21px;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 91px;
      margin-bottom: 18px;
    }
    span {
      font-size: 28px;
      font-family: AppleSystemUIFont;
      color: #666666;
      line-height: 41px;
      text-align: center;
    }
    .line {
      width: 100%;
      em {
        margin: 0 auto;
        display: block;
        width: 163px;
        height: 4px;
        background: #C82227;
        margin-top: 16px;
      }
    }
    .navBox {
      position: absolute;
      right: 30px;
      top: 456px;
      z-index: 99;
      .nav {
        margin-top: 15px;
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 60px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 21px;
          text-align: right;
          margin-right: 10px;
        }
        a {
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #000000;
          border-radius: 50%;
        }
      }
      .nav:nth-child(1) {
        margin-top: 0;
      }
      .active {
        background: #000000;
      }
    }
  }
  .footer {
    width: 100%;
    height: 936px;
    background-image: url(../assets/img/index/footerBg.png);
    background-position: 100%;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
    p {
      position: absolute;
      top: 565px;
      left: 360px;
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 67px;
    }
    .navBox {
      position: absolute;
      top: 400px;
      right: 30px;
      .nav {
        margin-top: 15px;
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 60px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 21px;
          text-align: right;
          margin-right: 10px;
        }
        a {
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #000000;
          border-radius: 50%;
        }
      }
      .nav:nth-child(1) {
        margin-top: 0;
      }
      .active {
        background: #000000;
      }
    }
  }
}
</style>