<template>
  <div class="coremanment">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
        <div v-if="heardersw">
        <titletop />
       </div>
    <div class="optionbander">
      <div class="bigsix">值得托付-彼此成就-共同富裕</div>
      <div class="smallsix" >为行业与社会做出巨大贡献的公司</div>
    </div>
    <div class="backsmaimg">
     <div class="posionband">
      <div class="posionbando">核心管理层</div>
      <div class="posionbandt" >让员工幸福</div>
    </div>
   </div>
    </div>
    
   <div class="comtonhx">
     <p class="comtonhxtitle">
      核心管理层
     </p>
     <div class="comtonhxall">
        <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu.png" alt="">
        <div class="xuanting">
         <p class="name">
          胡晓军
         </p>
         <p class="info">万鸿国际 | 总经理</p>
        </div>
        </div>
        <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu1.png" alt="">
        <div class="xuanting">
         <p class="name" style="margin-bottom: 10px">
          姚付进
         </p>
         <p class="info">鸿旗集团董事长助理、<br/>世贝材料、盛世兴恒 | 总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu2.png" alt="">
        <div class="xuanting">
         <p class="name">
          赵传龙
         </p>
         <p class="info">鸿旗电力 | 总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu3.png" alt="">
        <div class="xuanting">
         <p class="name">
          储康康
         </p>
         <p class="info">尾易网 | 执行总裁</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu4.png" alt="">
        <div class="xuanting">
         <p class="name">
          张一鸣
         </p>
         <p class="info">万鸿国际 | 副总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu5.png" alt="">
        <div class="xuanting">
         <p class="name">
          周卫新
         </p>
         <p class="info">万鸿国际-苏州烨贸 | 总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu6.png" alt="">
        <div class="xuanting">
         <p class="name">
          王凯冉
         </p>
         <p class="info">万鸿国际-广东兰格 | 总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu7.png" alt="">
        <div class="xuanting">
         <p class="name">
          蔡文
         </p>
         <p class="info">万鸿国际-成都万鸿昌 | 总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu8.png" alt="">
        <div class="xuanting">
         <p class="name">
          李金龙
         </p>
         <p class="info">万鸿国际-浙江中登 | 总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu9.png" alt="">
        <div class="xuanting">
         <p class="name">
          吕道军
         </p>
         <p class="info">万鸿国际-安徽鸿兴 | 总经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu10.png" alt="">
        <div class="xuanting">
         <p class="name">
          顾海松
         </p>
         <p class="info">万鸿国际业务一部 | 经理</p>
        </div>
        </div>
        <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu11.png" alt="">
        <div class="xuanting">
         <p class="name">
        刘治
         </p>
         <p class="info">万鸿国际业务一部 | 经理</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu12.png" alt="">
        <div class="xuanting">
         <p class="name">
          韩俊
         </p>
         <p class="info">战略投资部 | 总监</p>
        </div>
        </div> <div class="comtonhxitem">
        <img src="../assets/img/renwu/renwu13.png" alt="">
        <div class="xuanting">
         <p class="name">
          张晶琳
         </p>
         <p class="info">商控部 | 总监</p>
        </div>
        </div> <div class="comtonhxitem">
       <img src="../assets/img/renwu/renwu14.png" alt="">
       <div class="xuanting">
         <p class="name">
           钱晓磊
         </p>
         <p class="info">综合管理部 | 经理</p>
       </div>
     </div>
         <div class="comtonhxitemwww ">
       
        </div>
     </div>
     
   </div>
    


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
export default {
data(){
 return{
  heardersw:false,
 }
},
mounted(){
 console.log(this.$store.state.currPage);

},
methods:{

  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
 
}
}
</script>
<style lang="scss" scope>



.coremanment{
  overflow-x: hidden;
  width: 100%;
  // border: 1px solid red;
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/banbanne.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/bigbanhder.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}



}
.comtonhx{
  text-align: center;
  width: 1600px;
  margin-top: 160px;
  // border: 1px solid red;
  overflow: hidden;
  margin-left: 60px;
  .comtonhxtitle{
    width: 160px;
height: 45px;
    font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 45px;
padding-bottom: 30px;
margin: 50px auto;
border-bottom: 4px solid rgb(200,34,39);
  }
  .comtonhxall{
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    .comtonhxitemwww{
      width: 330px;
    }
    .comtonhxitem{
      width: 330px;
      height: 400px;
      margin-bottom: 50px;
      .xuanting{
        position: relative;
        top: -110px;
        .name{
          margin-bottom: 20px;
         
font-size: 26px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 37px;
        }
        .info{
          font-size: 18px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 25px;
        }
      }
    }
    .comtonhxitem:hover{
      box-shadow: 10px 10px 20px rgb(207, 205, 205);
    }
  }
}

</style>